import * as React from 'react';

import challengeSettings from '../../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';

import { st, classes } from './SidebarControlsContainer.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IDividerProps {
  className?: string;
  direction?: 'column' | 'row';
}

export const SidebarControlsContainer: FCWithChildren<IDividerProps> = (
  props,
) => {
  const { direction = 'row' } = props;
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const align = settings.get(challengeSettings.sidebarLayoutTextAlignment);
  const { isCurrentStepHidden } = useSidebarLayoutBase();

  if (isCurrentStepHidden) {
    return null;
  }

  return (
    <div
      className={st(
        classes.root,
        {
          align,
          direction,
          mobile: isMobile,
        },
        props.className,
      )}
      dir="auto"
    >
      {props.children}
    </div>
  );
};
