import React from 'react';

import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  Text,
  TextTypography,
  ButtonSize,
  Spinner,
  SpinnerTypes,
  ButtonPriority,
  Button,
} from 'wix-ui-tpa/cssVars';
import { UndoStepButton, useStepIsUnavailable } from '../StepControls';
import { Box } from '../../../../../components-shared/Box';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { useQuiz } from '../../../../../contexts/Quiz/QuizContext';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { useSettings } from '@wix/tpa-settings/react';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';
import utils, {
  INextEntity,
  useNextEntity,
  isStepResolved,
  ChallengeEntityType,
} from '../../views/utils';
import { memberWebAppButtonClick } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../../../../contexts/main/biInterfaces';
import { sidebarLayoutDataHooks } from '../../views/SidebarLayout/sidebarLayoutDataHooks';

import { st, classes } from './FormQuizControls.st.css';
import { SidebarControlsContainer } from '../SidebarControlsContainer/SidebarControlsContainer';
import { StepInTheFutureGuard } from '../StepInTheFutureGuard/StepInTheFutureGuard';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IFormQuizProps {
  formId: string;
  completeBtnDataHook?: string;
  isDisabled?: boolean;
  onNextEntity(nextEntity: INextEntity): Promise<void>;
}

export const FormQuizControls: FCWithChildren<IFormQuizProps> = ({
  // formId,
  // completeBtnDataHook,
  isDisabled,
  onNextEntity,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(settings);
  const {
    controllerIsReady,
    submission,
    sendSubmissionInProgress,
    earnedGrade,
    resubmitTheQuiz,
    pagesData,
    setPagesData,
  } = useQuiz();
  const { currentStep } = useSidebarLayoutBase();
  const { isResolveStepRequestInProgress } = useResolveStep();
  const bi = useBi();

  const { formQuizRef } = useSidebarLayoutBase();
  const { nextEntity } = useNextEntity(currentStep?.id);

  const isFilled = !!submission;
  const passingGrade = utils.getPassingGrade(currentStep, isFilled);
  const hasPassingGrade = !!passingGrade;
  const isQuizPassed = !hasPassingGrade || earnedGrade >= passingGrade;
  const isCompleted = isStepResolved(currentStep) && isFilled && isQuizPassed;

  const showSubmitBtn = !isStepResolved(currentStep);
  const showResubmitBtn = isFilled && hasPassingGrade && !isQuizPassed;
  const showNextBtn =
    isCompleted &&
    !!nextEntity &&
    nextEntity?.type !== ChallengeEntityType.VOID;
  const isUnavailable = useStepIsUnavailable();

  const isMultiplePages = pagesData?.pages?.length > 1;
  const currentPageInd = isMultiplePages
    ? pagesData?.pages?.findIndex((page) => page.id === pagesData.pageId)
    : null;
  const currentPage =
    currentPageInd !== -1 ? pagesData?.pages?.[currentPageInd] : null;
  const showPreviousPageBtn =
    isMultiplePages &&
    !sendSubmissionInProgress &&
    !isResolveStepRequestInProgress;
  const showNextPageBtn =
    isMultiplePages &&
    !currentPage?.isLast &&
    !sendSubmissionInProgress &&
    !isResolveStepRequestInProgress;

  const submit = React.useCallback(() => {
    if (formQuizRef?.current) {
      formQuizRef.current?.validate().then(
        (isValid) => {
          if (isValid) {
            void bi.report(
              memberWebAppButtonClick({
                buttonName: ButtonNames.QuizSubmit,
              }),
            );
            void formQuizRef.current?.submitTheForm();
          }
        },
        (err) => {
          console.error("Can't submit the form.", err);
        },
      );
    } else {
      console.error("Can't find the form component.");
    }
  }, [formQuizRef?.current?.validate, formQuizRef?.current?.submitTheForm]);

  if (!controllerIsReady || isUnavailable) {
    return null;
  }

  const submitBtn = (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
    >
      {sendSubmissionInProgress || isResolveStepRequestInProgress ? (
        <Spinner type={SpinnerTypes.slim} diameter={40} />
      ) : (
        <SidebarLayoutButton
          className={classes.completeBtn}
          dataHook={sidebarLayoutDataHooks.quizSubmitBtn({
            resubmit: showResubmitBtn,
          })}
          disabled={!!isDisabled || sendSubmissionInProgress}
          fullWidth={isMobile}
          size={isMobile ? ButtonSize.small : ButtonSize.medium}
          onClick={
            showResubmitBtn
              ? () => {
                  void bi.report(
                    memberWebAppButtonClick({
                      buttonName: ButtonNames.QuizResubmit,
                    }),
                  );
                  resubmitTheQuiz();
                  if (isMultiplePages) {
                    setPagesData({
                      pageId: pagesData?.pages?.[0]?.id,
                      pages: pagesData?.pages,
                    });
                  }
                }
              : submit
          }
        >
          {showResubmitBtn
            ? t('live-site.step-quiz.retake-btn')
            : t('live-site.step-quiz.complete-btn')}
        </SidebarLayoutButton>
      )}
    </PreviewActionBlockerContainer>
  );

  const previousPageBtn = (
    <Button
      disabled={
        !!isDisabled || sendSubmissionInProgress || currentPage?.isFirst
      }
      fullWidth={isMobile}
      size={isMobile ? ButtonSize.small : ButtonSize.medium}
      priority={ButtonPriority.secondary}
      onClick={async () => {
        if (!currentPage?.isFirst) {
          setPagesData({
            ...pagesData,
            pageId: pagesData.pages?.[currentPageInd - 1]?.id,
          });
        }
      }}
    >
      Previous
    </Button>
  );

  const nextPageBtn = (
    <SidebarLayoutButton
      dataHook={sidebarLayoutDataHooks.quizSubmitBtn()}
      disabled={!!isDisabled || sendSubmissionInProgress || currentPage?.isLast}
      fullWidth={isMobile}
      size={isMobile ? ButtonSize.small : ButtonSize.medium}
      onClick={async () => {
        const isValid = await (formQuizRef.current as any)?.validateStep(
          currentPage?.id,
        );

        if (!currentPage?.isLast && isValid) {
          setPagesData({
            ...pagesData,
            pageId: pagesData.pages?.[currentPageInd + 1]?.id,
          });
        }
      }}
    >
      Next
    </SidebarLayoutButton>
  );

  const nextBtn = (
    <SidebarLayoutButton
      fullWidth={isMobile}
      size={isMobile ? ButtonSize.small : ButtonSize.medium}
      onClick={async () => {
        void onNextEntity(nextEntity);
      }}
    >
      {t('live-site.step-quiz.next-btn')}
    </SidebarLayoutButton>
  );

  // eslint-disable-next-line no-nested-ternary
  const msg = isFilled
    ? isQuizPassed
      ? t('live-site.step-quiz.completed-msg')
      : t('live-site.step-quiz.failed-msg')
    : hasPassingGrade
    ? t('live-site.step-quiz.completed-msg-with-score', {
        score: passingGrade,
      })
    : null;
  const msgLine =
    msg && !isResolveStepRequestInProgress ? (
      <Text
        tagName="p"
        typography={TextTypography.runningText}
        className={classes.message}
      >
        {msg}
      </Text>
    ) : null;

  return (
    <SidebarControlsContainer>
      <div
        className={st(classes.root, {
          mobile: isMobile,
          alignment,
        })}
      >
        <StepInTheFutureGuard>
          <Box gap={3}>
            {showPreviousPageBtn ? previousPageBtn : null}
            {showNextPageBtn ? nextPageBtn : null}
            {(showSubmitBtn || showResubmitBtn) && !showNextPageBtn
              ? submitBtn
              : null}
            {showNextBtn && !showNextPageBtn ? nextBtn : null}
            <UndoStepButton>{t('live-site.step-quiz.undo')}</UndoStepButton>
          </Box>
          {msgLine}
        </StepInTheFutureGuard>
      </div>
    </SidebarControlsContainer>
  );
};
