import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React, { useEffect, useState } from 'react';
import { useIsStepHidden } from '../../../../../contexts/IsStepHidden/IsStepHiddenContext';
import { useUser } from '../../../../../contexts/User/UserContext';
import utils, {
  isStepAvailableForComplete,
  isQuizFailed,
} from '../../views/utils';

import challengeSettings from '../../../settingsParams';
import { StepIcon } from '../../../../../components-shared/StepIcon';
import { useMonitoring } from '../../../../../contexts/Monitoring/MonitoringContext';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';

import { FCWithChildren } from '@wix/challenges-web-library';
import { isUserCompleted } from '../../../../../contexts/User/helpers/userTypeHandlers';
import { getTitle } from '../../../../../selectors/step';
import { useVideoStepData } from '../../../../../hooks/useVideoStepData';
import { formatProgramVideoDuration } from '../VideoPlayerInStep/formatDuration';

import { st, classes } from './StepsListAsTiles.st.css';

export interface IStepSidebar {
  step: ParticipantStep;
  isSPC: boolean;
  currentStepId: string;
  onStepChosen: Function;
  className?: string;
}

export const StepSidebar: FCWithChildren<IStepSidebar> = (props) => {
  const { t } = useTranslation();
  const { isSPC, currentStepId, onStepChosen, step } = props;
  const { isStepHidden, isVisibleStepLockedForComplete } = useIsStepHidden();
  const {
    participant,
    participantV3,
    isParticipantInSuspendedState,
    isParticipantInLockedState,
  } = useUser();
  const settings = useSettings();
  const { startInteraction } = useMonitoring();
  const { isRTL } = useEnvironment();
  const isParticipantInNegativeState =
    isParticipantInSuspendedState || isParticipantInLockedState;
  const isParticipantCompletedChallengeState = isUserCompleted(
    participant?.transitions?.[0]?.state,
  );

  const isStepUnavailable =
    isStepHidden(step) || isVisibleStepLockedForComplete(step);
  const tooltipContent = t(
    utils.getStepTooltipKey(step, isStepUnavailable, isSPC, participant),
  );
  const tooltipDisabled =
    !isUserCompleted(participant?.transitions?.[0]?.state) &&
    isStepAvailableForComplete(step) &&
    !isStepUnavailable;
  const [isCurrent, setIsCurrent] = useState(step?.id === currentStepId);

  useEffect(() => {
    setIsCurrent(step?.id === currentStepId);
  }, [currentStepId, step?.id]);

  const { videoStepDuration } = useVideoStepData(step?.source);

  return (
    <li
      className={st(classes.stepItem, {}, isCurrent && classes.stepItemActive)}
      data-id={step?.id}
      key={step?.id}
      onClick={() => {
        setIsCurrent(true);
        startInteraction?.(FedopsInteraction.ViewStepAtSidebarLayout, step?.id);
        // This is a workaround to improve INP performance for the action.
        // It makes next paint prior to the heavy action.
        setTimeout(() => {
          onStepChosen(step);
        });
      }}
      dir={isRTL ? 'rtl' : 'auto'}
    >
      <button
        className={st(classes.stepLink, {
          align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        })}
        onClick={() => {}}
      >
        <div className={classes.stepItemIcon}>
          {tooltipDisabled ? null : (
            <div className={classes.srOnly}>{tooltipContent}</div>
          )}
          <StepIcon
            step={step}
            isUnavailable={isStepUnavailable}
            participant={participantV3 || participant}
            isParticipantInNegativeState={isParticipantInNegativeState}
          />
        </div>
        <div className={classes.gapBetweenTitleAndIcon} />

        <p className={classes.stepItemTitle} dir="auto">
          {getTitle(step?.source)}
        </p>

        {videoStepDuration &&
        !isParticipantInNegativeState &&
        !isParticipantCompletedChallengeState ? (
          <p className={classes.stepItemSubtitle} dir="auto">
            {/* t(`live-site.step-type-subtitle.${stepType.toLowerCase()}`) removed for now */}
            {videoStepDuration
              ? formatProgramVideoDuration(t, videoStepDuration)
              : ''}
          </p>
        ) : null}

        {isQuizFailed(step) ? (
          <p className={classes.stepItemSubtitle} dir="auto">
            {t('live-site.step-quiz.step-failed-subtitle')}
          </p>
        ) : null}
      </button>
    </li>
  );
};

StepSidebar.displayName = 'StepSidebarItem';
