import React, { Ref } from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  ParticipantSection,
  ParticipantStep,
} from '@wix/ambassador-challenges-v1-participant/types';

import { Back } from '../icons/Back';
import { Breadcrumbs } from '../../../../../components-shared/Breadcrumbs';

import { classes, st } from './StepViewBreadcrumbs.st.css';
import utils from '../../views/utils';
import {
  getSectionTitle,
  sectionCannotBeOpenedInView,
} from '../../../../../selectors/sections';
import { getTitle } from '../../../../../selectors/step';

export interface IStepViewBreadcrumbsProps {
  sections?: ParticipantSection[];
  currentStep?: ParticipantStep;
  goToMobileList(): void;
  onSectionChosen?(id: string): void;
  btnRef?: Ref<HTMLButtonElement>;
}

export const StepViewBreadcrumbs: React.FunctionComponent<
  IStepViewBreadcrumbsProps
> = ({
  sections,
  currentStep,
  goToMobileList,
  onSectionChosen,
  btnRef,
}: IStepViewBreadcrumbsProps) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(settings);

  const currentSection = (sections || []).find((section) => {
    return section?.steps?.some((step) => step?.id === currentStep?.id);
  });

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        alignment,
      })}
      dir={isRTL ? 'rtl' : 'auto'}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {isMobile ? (
        <p className={classes.mobileBackButton} onClick={goToMobileList}>
          <Back />
          <span dir="auto">
            {t('live.challenges-page-sidebar.mobile-back-button')}
          </span>
        </p>
      ) : !sectionCannotBeOpenedInView(currentSection) ? (
        <Breadcrumbs className={classes.breadcrumbs}>
          <Breadcrumbs.Link
            text={getSectionTitle(currentSection.source)}
            onClick={() => onSectionChosen(currentSection.id)}
            btnRef={btnRef}
          />
          <Breadcrumbs.Separator />
          <Breadcrumbs.Link text={getTitle(currentStep?.source)} />
        </Breadcrumbs>
      ) : null}
    </div>
  );
};
StepViewBreadcrumbs.displayName = 'StepViewBreadcrumbs';
