import React, { useEffect, useMemo, useRef } from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { ButtonSize as ButtonSIZE } from 'wix-ui-tpa/cssVars';

import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import utils, {
  ChallengeEntityType,
  getNextButtonLabel,
  isStepAvailableForComplete,
  isStepOverdue,
  isStepResolved,
} from '../../views/utils';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { IControl } from './types';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { useMonitoring } from '../../../../../contexts/Monitoring/MonitoringContext';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';

import { UndoStepButton } from './UndoStepButton';
import { Box } from '../../../../../components-shared/Box';
import { useStepIsUnavailable } from './useStepIsUnavailable';
import { StepInTheFutureGuard } from '../StepInTheFutureGuard/StepInTheFutureGuard';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { useVideoStepData } from '../../../../../hooks/useVideoStepData';

import { classes, st } from './StepControls.st.css';

export interface IStepControls extends IControl {
  completeBtnDataHook?: string;
  nextBtnDataHook?: string;
  currentStep: ParticipantStep;
  isCompletedChallenge: boolean;
  buttonState: string;
  onStepResolve(currentStep: ParticipantStep): Promise<void>;
}

export const StepControls: FCWithChildren<IStepControls> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { isResolveStepRequestInProgress } = useResolveStep();
  const { startInteraction } = useMonitoring();
  const stepId = props.currentStep?.id;

  const isStepUnavailable = useStepIsUnavailable();

  const nextEntity = utils.getNextEntity(props.currentStep?.id, {
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });

  const isCompleteButtonShown =
    !props.isCompletedChallenge &&
    (isStepAvailableForComplete(props.currentStep) ||
      isStepOverdue(props.currentStep));

  const { isVideo } = useVideoStepData(props.currentStep?.source);
  const { videoStepAccepted, isVideoLoadingError } = useSidebarLayoutBase();
  const [isVideoNotificationVisible, setIsVideoNotificationVisible] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setIsVideoNotificationVisible(false);
  }, [stepId, videoStepAccepted]);

  const nextRef = useRef(null);
  useEffect(() => {
    if (nextRef.current && !isResolveStepRequestInProgress) {
      nextRef.current.focus();
    }
  }, [nextRef.current, isResolveStepRequestInProgress]);
  const showNext =
    isStepResolved(props.currentStep) &&
    nextEntity.type !== ChallengeEntityType.VOID;

  const NextButton = useMemo(() => {
    return showNext ? (
      <SidebarLayoutButton
        className={classes.stepViewMainCTA}
        key={stepId}
        dataHook={props.nextBtnDataHook || null}
        disabled={isResolveStepRequestInProgress}
        fullWidth={isMobile}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          await props.onNextEntity(nextEntity);
        }}
        btnRef={nextRef}
      >
        {getNextButtonLabel({
          entity: nextEntity.type,
          t,
          nextButtonTxt: settings.get(
            challengeSettings.nextButtonTxt,
          ) as string,
        })}
      </SidebarLayoutButton>
    ) : null;
  }, [showNext, nextEntity?.id, isResolveStepRequestInProgress]);

  if (isStepUnavailable) {
    return null;
  }

  const CompleteButton = isCompleteButtonShown ? (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
      isActionAllowed={false}
    >
      <SidebarLayoutButton
        className={classes.stepViewMainCTA}
        dataHook={props.completeBtnDataHook || null}
        disabled={isResolveStepRequestInProgress || isVideoLoadingError}
        fullWidth={isMobile}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          if (isVideo && !videoStepAccepted) {
            setIsVideoNotificationVisible(true);
            return;
          }

          if (!isResolveStepRequestInProgress) {
            startInteraction(
              FedopsInteraction.CompleteStepWithoutFeedback,
              props.currentStep?.id,
            );

            await props.onStepResolve(props.currentStep);
          }
        }}
      >
        {settings.get(challengeSettings.completeButtonTxt) as string}
      </SidebarLayoutButton>
    </PreviewActionBlockerContainer>
  ) : null;

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        buttonState: props.buttonState,
        stepResolved: isStepResolved(props.currentStep),
      })}
      role="region"
      aria-live="polite"
    >
      <StepInTheFutureGuard>
        {isStepResolved(props.currentStep) ? (
          <>
            {isMobile ? (
              <>
                <p className={classes.stepCompletedText}>
                  <TextWithDirection>
                    {t('challenge.page.step.completed')}
                  </TextWithDirection>
                </p>
                {NextButton}
              </>
            ) : (
              <>
                <p className={classes.stepCompletedText}>
                  <TextWithDirection>
                    {t('challenge.page.step.completed')}
                  </TextWithDirection>
                </p>
                {NextButton ? (
                  <Box gap={6} className={classes.boxNextButton}>
                    {NextButton}
                    <UndoStepButton>{t('live-site.step.undo')}</UndoStepButton>
                  </Box>
                ) : null}
              </>
            )}
          </>
        ) : null}

        {CompleteButton}
        {isVideoNotificationVisible ? (
          <p className={classes.stepCompletedText}>
            <TextWithDirection>
              {t('challenge.page.step-video-required.warning')}
            </TextWithDirection>
          </p>
        ) : null}
      </StepInTheFutureGuard>
    </div>
  );
};
